export const FSCollectionStatus = {
	Relevant: 'relevant',
	Archive: 'archive',
	NoRelevant: 'no_relevant',
	NoRelevantAndArchive: 'no_relevant,archive',

};

export type FSCollectionStatus =
	(typeof FSCollectionStatus)[keyof typeof FSCollectionStatus];
