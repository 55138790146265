export interface FSShareOption {
	name: string;
	type: FSShareType;
}

export const FSShareType = {
	Inside: 'inside',
	Outside: 'outside',
};

export type FSShareType = (typeof FSShareType)[keyof typeof FSShareType];
