import { FSFilterHandbook } from './filter-handbook.type';

export interface FSFilter {
	page: string;
	limit: string;
	sort: string;
	search: string;
	archive: boolean;
	irrelevant: boolean;
	type_collection: FSFilterHandbook[];
	directions: FSFilterHandbook[];
	layout_type: FSFilterHandbook[];
}

export const FSFilterKeys = {
	Page: 'page',
	Limit: 'limit',
	Sort: 'sort',
	Search: 'search',
	Archive: 'archive',
	Irrelevant: 'irrelevant',
	TypeCollection: 'type_collection',
	Directions: 'directions',
	LayoutType: 'layout_type',
};

export type FSFilterKeys = (typeof FSFilterKeys)[keyof typeof FSFilterKeys];
